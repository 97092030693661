import { ref, type Ref } from 'vue';
import type { MyCustomerUser } from '~/types/models/mycustomer';

export function useMyCustomer() {
    const { apiClient } = useShopwareContext();

    const users: Ref<MyCustomerUser[]> = useState('myCustomers', () => ref([]));
    const limit = 50;
    const currentPage = useState('myCustomersCurrentPage', () => ref(1));
    const count: Ref<number> = useState('myCustomersUserCount', () => ref(0));
    const failed = useState('myCustomersFailed', () => ref(false));
    const loading = useState('myCustomersLoading', () => ref(false));
    const loggingAs = useState('myCustomersLoggingAs', () => ref(''));

    const totalPages = computed(() => Math.ceil(count.value / limit));

    const searchUsers = async (searchTerm: string) => {
        if (loading.value || !searchTerm || searchTerm.length <= 2) return;

        loading.value = true;

        try {
            const fetchedUsers = await apiClient.invoke('searchCustomerAccounts get /my-customer/search?search', {
                search: searchTerm,
            });

            users.value = fetchedUsers;
            count.value = fetchedUsers?.length;
        } catch (error) {
            Logger.captureException(error);
            failed.value = true;
        } finally {
            loading.value = false;
        }

        return users.value;
    };

    /** requests search route without param to check if user is eligible to access mycustomer page.
     * Backend Route checks if user is logged in, has my_customer_account flag or isDelegatedLoginSession and throws 'not allowed error' otherwise.
     * Because we cant check isDelegatedLoginSession (session.payload) in frontend */
    const validateAccess = async () => {
        try {
            await apiClient.invoke('searchCustomerAccounts get /my-customer/search', {});
        } catch (error: any) {
            // status codes are messed up. Shopware sends code 403 for both notLoggedIn and insufficientPermissions. While our custom error uses code 401.
            if (error.status === 403) return { success: false, status: 403 }; // not logged in
            if (error.status === 401) return { success: false, status: 401 }; // error.status 500, not sufficient permissions
        }

        return { success: true }; // error.status 500, 'Minimum search term length' is ok
    };

    // there is no backend pagination, so we do it manually
    const paginatedUsers = computed(() => {
        const startIndex = (currentPage.value - 1) * limit;
        const endIndex = startIndex + limit;
        return users.value.slice(startIndex, endIndex);
    });

    const goToPage = (pageNumber: number) => {
        currentPage.value = Math.max(1, Math.min(pageNumber, totalPages.value));
    };

    const loginAsUser = async (globalAccountId: string) => {
        if (loggingAs.value || !globalAccountId) return;

        loggingAs.value = globalAccountId;

        try {
            await apiClient.invoke('delegatedLogin post /mycustomer/delegated-login?globalContactId', {
                globalContactId: globalAccountId,
            });
        } catch (error) {
            Logger.captureException(error);
        } finally {
            loggingAs.value = '';
        }
    };

    return {
        paginatedUsers,
        goToPage,
        searchUsers,
        validateAccess,
        loginAsUser,
        count,
        limit,
        totalPages,
        currentPage,
        failed,
        loading,
        loggingAs,
    };
}
